
// Platform-specific features.

import React from 'react'
import ReactDOM from 'react-dom'
import Dropzone from 'react-dropzone'
import Paper from '@mui/material/Paper';
import { createRoot } from 'react-dom/client';

import { library } from '@fortawesome/fontawesome-svg-core'

import { faGavel, faStar, faEye, faEyeSlash, faWindowMaximize, faWindowRestore, faPen, faTrashCan, faCircleCaretRight, faCircleCaretDown, faFile, faMessageExclamation, faMessageLines, faStreetView, faLocationDot, faBookSparkles, faRoute, faFlaskRoundPotion, faNewspaper, faUserGroupCrown, faSquareQuestion, faSquareExclamation } from '@fortawesome/pro-duotone-svg-icons'
import { faGauge, faKeyboard, faDownload, faAngleUp, faAngleDown, faCircleQuestion, faEnvelopeOpenText, faExpandWide, faSquarePen, faBolt, faGear, faArrowsRotate, faVolumeHigh, faVolumeLow, faVolumeOff, faSquareMinus, faSquarePlus, faArrowUpFromBracket, faArrowDownToBracket, faCircleMinus, faCirclePlus, faPersonSimple, faPeopleGroup } from '@fortawesome/pro-duotone-svg-icons'
import { faSignalStrong, faSignalGood, faSignalFair, faSignalWeak, faSignalSlash, faBooks, faTreasureChest, faLandmark, faBullseyeArrow } from '@fortawesome/pro-duotone-svg-icons'
import { faSkullCow, faHoodCloak, faEarDeaf, faSkull, faWeightHanging, faCircleHalfStroke, faEar, faSnooze, faHeartPulse, faFeatherPointed, faPodcast, faMoon, faCircleDot, faShieldBlank, faCloud, faFish, faPersonRunning, faAsterisk, faClouds, faShield } from '@fortawesome/pro-duotone-svg-icons'


import { faGear as faGearR, faTrashCan as faTrashCanR } from '@fortawesome/pro-regular-svg-icons'

import { faCircleXmark as faCircleXmarkS, faAnglesLeft as faAnglesLeftS, faAnglesRight as faAnglesRightS, faArrowLeft as faArrowLeftS, faArrowRight as faArrowRightS, faSquarePlus as faSquarePlusS, faSquareMinus as faSquareMinusS, faSquareQuestion as faSquareQuestionS, faClone as faCloneS, faTrashCan as faTrashCanS } from '@fortawesome/pro-solid-svg-icons'
import { faCaretUp as faCaretUpS, faCaretDown as faCaretDownS, faFloppyDisk as faFloppyDiskS, faRotate as faRotateS, faTrash as faTrashS, faBolt as faBoltS, faVolumeHigh as faVolumeHighS, faVolumeLow as faVolumeLowS, faVolumeOff as faVolumeOffS, faSquare as faSquareS, faSquareCheck as faSquareCheckS } from '@fortawesome/pro-solid-svg-icons'
import { fas } from '@fortawesome/pro-solid-svg-icons'
// TODO items - keeping fas around for now

import { ReflexKeybind } from '../reflexes/keys.js'
import { ActionList } from '../reflexes/actions.js'

import { App } from '../components/app.js'
import { Prefetch } from '../prefetch'

export class NexusPlatform {
  constructor(nexus) {
    this._nexus = nexus;
    this._App = React.createRef();
    this.React = React;  // for 3rdparty scripting
    this.ReactDOM = ReactDOM;  // for 3rdparty scripting
    globalThis.React = React;  // for 3rdparty scripting
    globalThis.ReactDOM = ReactDOM;  // for 3rdparty scripting
  }

  setup() {
    var t = this;

    // font-awesome
    // dualtone
    library.add(faGavel, faStar, faEye, faEyeSlash, faWindowMaximize, faWindowRestore, faPen, faTrashCan, faCircleCaretDown, faCircleCaretRight, faFile, faMessageExclamation, faMessageLines, faStreetView, faLocationDot, faBookSparkles, faRoute, faFlaskRoundPotion, faNewspaper, faUserGroupCrown, faSquareQuestion, faSquareExclamation);
    library.add(faGauge, faKeyboard, faDownload, faAngleUp, faAngleDown, faCircleQuestion, faEnvelopeOpenText, faExpandWide, faSquarePen, faBolt, faGear, faArrowsRotate, faVolumeHigh, faVolumeLow, faVolumeOff, faSquareMinus, faSquarePlus, faArrowUpFromBracket, faArrowDownToBracket, faCircleMinus, faCirclePlus, faPersonSimple, faPeopleGroup);
    library.add(faSignalStrong, faSignalGood, faSignalFair, faSignalWeak, faSignalSlash, faBooks, faTreasureChest, faLandmark, faBullseyeArrow);
    library.add(faSkullCow, faHoodCloak, faEarDeaf, faSkull, faWeightHanging, faCircleHalfStroke, faEar, faSnooze, faHeartPulse, faFeatherPointed, faPodcast, faMoon, faCircleDot, faShieldBlank, faCloud, faPersonRunning, faAsterisk, faFish, faClouds, faShield);

    // solid
    library.add(faCircleXmarkS, faAnglesLeftS, faAnglesRightS, faArrowLeftS, faArrowRightS, faSquarePlusS, faSquareMinusS, faSquareQuestionS, faCloneS, faTrashCanS);
    library.add(faCaretUpS, faCaretDownS, faFloppyDiskS, faRotateS, faTrashS, faBoltS, faVolumeHighS, faVolumeLowS, faVolumeOffS, faSquareS, faSquareCheckS);
    library.add(fas);

    // regular
    library.add(faGearR, faTrashCanR);

    this.prefetchImages();

    this.content = this._nexus.ui().layout().get_layout();
    this.app = (<App ref={this._App} nexus={this._nexus}>{this.content}</App>);
    t.render(this.app, 'root');

    t.set_timeout(function() {
      t.focus_input();
    }, 1000);

    // favicons
    t.add_link_tag(this._nexus.startup['game_files'] + '/images/favicon.ico', { 'rel': 'shortcut icon' });
    // apple icons
    for (var size in [144, 114, 72, 57])
      t.add_link_tag(this._nexus.startup['game_files'] + '/images/logo-' + size + '.png', { 'rel': 'apple-touch-icon-precomposed', 'sizes': size + 'x' + size });

    window.addEventListener('focus', function(e) {
      if (e.target === window) t._nexus.ui().redraw();
      t._nexus.on_activated();
    });
    window.addEventListener('blur', function(e) {
      t._nexus.on_deactivated();
    });

    window.addEventListener('beforeunload', function(e) {
      let question = t.closing_question();
      if (!question) return;

      // for electron, we need to handle things differently - so what we do is block the attempt, ask the user, then force close the window if they confirm
      if (t.is_desktop()) {
        t.confirm('Really Close?', question, () => {
          t.forceClose = true;
          window.close();
        });
        // and continue to the regular code - we do want to block this event
      }

      e.preventDefault();
      e.returnValue = question;
      return question;
    });

    window.addEventListener('keydown', function(e) {
      var key = e.which;
      var isAlt = (e.altKey) ? true : false;
      var isCtrl = (e.ctrlKey || e.metaKey) ? true : false;
      var isShift = (e.shiftKey) ? true : false;

      var res = t.on_key_down(key, isAlt, isCtrl, isShift);

      if (!res) e.preventDefault();
      return res;
    });

    window.addEventListener('keyup', function(e) {
      var key = e.which;
      var isAlt = (e.altKey) ? true : false;
      var isCtrl = (e.ctrlKey || e.metaKey) ? true : false;
      var isShift = (e.shiftKey) ? true : false;

      var res = t.on_key_up(key, isAlt, isCtrl, isShift);

      if (!res) e.preventDefault();
      return res;
    });

    window.addEventListener('click', function(e) {
      t.click();
    });

    document.addEventListener('fullscreenchange', function() {
      let fs = t.is_fullscreen();
      t._nexus.ui().layout().fullscreenChange(fs);
    });

    if (this.window_height() <= 768) this._nexus.settings().font_size = '11px';  // reduce default font size if they are on a small window size (settings will override this)

    this.handle_gameinfo_changed();
  }

  closing_question() {
    if (this.forceClose) return null;  // forces closing

    if (this._nexus.datahandler().is_connected())
      return 'You are currently logged in. Do you really want to close the window?';
    else if (this._nexus.system_changed())
      return "You have unsaved changes to your settings. Close without saving?";
    return null;
  }

  prefetchImages() {
    let lst = Prefetch.images();
    let body = document.body;
    for (let idx = 0; idx < lst.length; ++idx) {
      let el = document.createElement('img');
      el.setAttribute('style', 'display:none');
      el.setAttribute('src', lst[idx]);
      body.appendChild(el);
    }
  }

  setup_google_analytics() {
    // remove old analytics, if any
    let old = document.getElementById('googleanalytics');
    if (old && old.parentNode) old.parentNode.removeChild(old);

    // google analytics
    let gi = this._nexus.gameinfo();
    if (!gi) return;
    var gakey = gi.google_analytics();
    if (gakey && gakey.length) {
      var _gaq = _gaq || [];
      _gaq.push(['_setAccount', gakey]);
      _gaq.push(['_trackPageview']);

      (function() {
        var ga = document.createElement('script'); ga.type = 'text/javascript'; ga.id = 'googleanalytics'; ga.async = true;
        ga.src = ('https:' === document.location.protocol ? 'https://ssl' : 'http://www') + '.google-analytics.com/ga.js';
        document.head.appendChild(ga);
      })();
    }
  }

  handle_gameinfo_changed() {
    let gi = this._nexus.gameinfo();
    if (gi) document.title = 'Nexus - ' + gi.game_short();

    this.setup_google_analytics();
  }

  os() {
    return navigator.platform;
  }

  render(component, el_id) {
    let container = document.getElementById(el_id);
    const rootEl = createRoot(container);
    rootEl.render(component);
  }

  get_element_by_id(id) {
    return document.getElementById(id);
  }

  add_link_tag(href, params) {
    var tag = document.createElement('link');
    tag.href = href;
    for (let p in params)
      tag[p] = params[p];
    document.head.appendChild(tag);
  }

  el(tagName, params = null, content = null) {
    return React.createElement(tagName, params, content);
  }

  set_title(title) {
    document.title = title;
  }

  window_height() {
    return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  }

  window_width() {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  }

  // we don't use this for now (only the mobile version), but adding it for completeness sake
  is_landscape() {
    let h = this.window_height();
    let w = this.window_width();
    if (w > h) return true;
    return false;
  }

  set_timeout(fn, ms) {
    return window.setTimeout(fn, ms);
  }

  clear_timeout(t) {
    window.clearTimeout(t);
  }

  set_interval(fn, ms) {
    return window.setInterval(fn, ms);
  }

  clear_interval(t) {
    window.clearInterval(t);
  }

  confirm(title, content, onConfirm) {
    this._nexus.ui().layout().confirm(title, content, onConfirm);
  }

  alert(title, content, onConfirm) {
    if (!content) { content = title; title = 'Alert'; }
    this._nexus.ui().layout().alert(title, content, onConfirm);
  }

  on_volume_changed(vol) {
    this.set_local_setting("IRE.Volume", vol);
    this._nexus.ui().layout().gmcp_updated();   // not quite right as it updates everything, but it'll do, we don't change volume that often
  }

  get_volume() {
    let vol = this.local_setting("IRE.Volume");
    if (vol === undefined) vol = 50;
    return vol;
  }

  local_setting(s) {
    return localStorage['setting-' + s];
  }

  set_local_setting(s, val) {
    localStorage['setting-' + s] = val;
  }

  starting_game() {
    let domain = window.location.host;
    if (domain.includes('achaea.com')) return -1;
    if (domain.includes('aetolia.com')) return -2;
    if (domain.includes('imperian.com')) return -3;
    if (domain.includes('lusternia.com')) return -4;
    if (domain.includes('starmourn.com')) return -5;
    return 0;
  }

  focus_input() {
    if (!this.inputRef) return;
    if (!this.inputRef.current) return;
    this.inputRef.current.focus();
  }

  select_input() {
    if (!this.inputRef) return;
    if (!this.inputRef.current) return;
    this.inputRef.current.select();
  }

  set_input(text) {
    if (!this.inputRef) return;
    if (!this.inputRef.current) return;
    this.inputRef.current.value = text;
  }

  scroll_output(by) {
    if (!this.outputComponent) return;
    this.outputComponent.scrollBy(by);
  }

  scroll_resize(by) {
    let sett = this._nexus.settings();
    let cur = sett.scrollback_height;
    if ((cur <= 10) && (by < 0)) return;
    if ((cur >= 60) && (by > 0)) return;
    sett.scrollback_height += by;
    // repaint
    this._nexus.ui().layout().relayout();
  }

  scroll_hide() {
    if (!this.outputComponent) return;
    this.outputComponent.scrollToBottom();
  }

  output_height() {
    if (!this.outputComponent) return 0;
    return this.outputComponent.viewportHeight();
  }

  open_url(url) {
    window.open(url);
  }

  beep() {
    this._nexus.ui().sounds().play_sound("sfx/beep 2", false, false);
    var title = document.title;
    if (title.substr(0, 5) === 'ALERT') return;
    document.title = "ALERT - " + title;
    this.set_timeout(function() {
      document.title = title;
    }, 1000);
  }

  click() {
    //this._nexus.ui().sounds().play_sound("click", false, false);
  }

  supports_fullscreen() {
    var el = document.documentElement;
    if (el.requestFullscreen) return true;
    if (el.msRequestFullscreen) return true;
    if (el.mozRequestFullScreen) return true;
    if (el.webkitRequestFullscreen) return true;
    return false;
  }

  is_fullscreen() {
    if (!this.supports_fullscreen()) return false;

    if (document.fullscreenElement ||    // alternative standard method
      document.mozFullScreenElement || document.webkitFullscreenElement || document.msFullscreenElement)  // current working methods
      return true;
    return false;
  }

  enter_fullscreen() {
    if (!this.supports_fullscreen()) return;
    if (this.is_fullscreen()) return;

    var el = document.documentElement;
    if (el.requestFullscreen) {
      el.requestFullscreen();
    } else if (el.msRequestFullscreen) {
      el.msRequestFullscreen();
    } else if (el.mozRequestFullScreen) {
      el.mozRequestFullScreen();
    } else if (el.webkitRequestFullscreen) {
      el.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
    }
  }

  leave_fullscreen() {
    if (!this.supports_fullscreen()) return;
    if (!this.is_fullscreen()) return;

    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  }

  toggle_fullscreen() {
    this.is_fullscreen() ? this.leave_fullscreen() : this.enter_fullscreen();
  }

  // this uses the browser event directly
  on_key_down(key, isAlt, isCtrl, isShift) {
    // Esc exits fullscreen - this needs to work even if not connected
    if ((key === 27) && this.is_fullscreen()) {
      this.leave_fullscreen();
      return false;
    }

    if (this.keygrabber) {
      this.keygrabber.onKeyGrab(key, isAlt, isCtrl, isShift);
      return false;
    }

    if (!this._nexus.datahandler().is_connected()) {
      // disable ctrl +/- resizing
      if (isCtrl && ((key === 107) || (key === 109))) return false;
      return true;
    }

    // ESC key closes some things
    if (key == 27) {
      let layout = this._nexus.ui().layout();
      /*
                  if (layout.settings_shown()) {
                      layout.show_settings(false);
                      return false;
                  }
      */
      if (layout.close_escapable_dialogs()) {
        this.focus_input();
        return false;
      }
    }

    if (this.dialog_has_focus()) return true;   // nothing if we're in some dialog
    if ((key == 16) || (key == 17) || (key == 18)) return true;   // shift/ctrl/alt

    if ((key === 9) && (!isShift)) {   // TAB-targeting; shift+tab is tab-completion
      this.tab_pressed = true;
      this.player_targetted = false;
      // this.focus_input();
      return false;
    }

    if ((key === 32) && this.tab_pressed) {  // tab+space
      this._nexus.datahandler().tab_target(true);
      this.player_targetted = true;

      this.focus_input();
      return false;
    }

    // scrolling
    if (isShift && (!isCtrl) && ((key === 38) || (key === 40))) {       // shift Up / Down
      let by = 35;
      this.scroll_output((key === 40) ? by : -1 * by);
      return false;
    }
    if (/*isShift &&*/ ((key === 33) || (key === 34))) {     // Page Up / Down
      let by = this.output_height() * 0.75;
      this.scroll_output((key === 34) ? by : -1 * by);
      return false;
    }
    if (isCtrl && (key == 13)) {   // Ctrl + Enter
      this.scroll_hide();
      return false;
    }

    if (isShift && isCtrl && ((key === 38) || (key === 40))) {       // Ctrl + Shift + Up / Down
      let by = 5;
      this.scroll_resize((key === 38) ? by : -1 * by);
      return false;
    }

    var binding = ReflexKeybind.find_binding(key, isShift, isCtrl, isAlt, this._nexus.reflexes());

    if (binding !== null) {
      var match = binding.do_matching();  // a hack so we don't need special treatment here
      var actions = new ActionList(this._nexus);
      actions.prepare('keybind', binding, match);
      actions.execute(0);
      return false;
    }

    // f1-f6 trigger buttons - these are after bindings, so that bindings override buttons
    if (this._nexus.settings().allow_button_keys && (key >= 112) && (key < 112 + this._nexus.ui().buttons().count) && (!isShift) && (!isCtrl) && (!isAlt)) {
      if (this.buttonsComponent) this.buttonsComponent.execute(key - 112 + 1, false);
      return false;
    }

    // disable ctrl +/- resizing
    if (isCtrl && ((key === 107) || (key === 109))) return false;

    // No special-meaning key and no other element has focus - focus the input line
    if ((!this.something_has_focus()) && (!isAlt) && (!isCtrl) && (!isShift)) this.focus_input();

    return true;
  }

  on_key_up(key, isAlt, isCtrl, isShift) {
    if (!this._nexus.datahandler().is_connected()) return true;
    if (this.dialog_has_focus()) return true;   // nothing if we're in some dialog
    if (!this.tab_pressed) return true;

    if (key === 9) {   // TAB-targeting
      // this can only happen on releasing the key, as upon pressing it we do not yet know if we're going to also press Space or not
      this.tab_pressed = false;
      if (!this.player_targetted)   // this check ensures that we don't target a mob after releasing Tab after the Tab+Space press
        this._nexus.datahandler().tab_target(false);
      this.player_targetted = false;

      // this.focus_input();
      return false;
    }

    return true;
  }


  // check if we're on a mobile device
  real_mobile() {
    return false;
  }

  is_desktop() {  // this checks for the desktop version - i.e. electron
    let userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf(' electron/') > -1) return true;
    return false;
  }

  is_active() {
    return true;
  }

  // should we show the mobile display? this may become outdated and replaced by real_mobile, but for now let's keep it separated
  narrow_display() {
    if (this.window_width() <= 1000) return true;
    return false;
  }

  tooltips_shown() {
    if (this.real_mobile()) return false;
    return this._nexus.settings().tooltips_enabled;
  }

  supports_floaters() {
    if (this.real_mobile()) return false;
    return true;
  }

  // Returns true if the focused element is inside a dialog. Used to determine whether keybindings should trigger or not (we don't want to run these inside settings).
  dialog_has_focus() {
    let el = document.activeElement;
    if (!el) return false;
    while (el) {
      if (el.classList && el.classList.contains('nexusDialog')) return true;
      if (el.classList && el.classList.contains('MuiDialog-root')) return true;
      el = el.parentElement;
    }
    return false;
  }

  something_has_focus() {
    let el = document.activeElement;
    if (!el) return false;
    // body?
    let tag = document.activeElement.tagName.toLowerCase();
    if (tag === 'body') return false;
    if (tag === 'html') return false;
    // chrome 127+ gives focus to these ...
    if (tag === 'div') return false;
    if (tag === 'span') return false;
    return true;
  }

  get_download_url(data) {
    if (data.length > 1536 * 1024) {
      // chrome can't handle data above 2 MB, so let's re-route everything above 1.5MB to use blobs instead
      var b = new Blob([data], { type: 'text/plain' });
      var url = URL.createObjectURL(b, 'text/plain');
      this.set_timeout(function() { URL.removeObjectURL(url); }, 30 * 1000);
      return url;
    }

    return 'data:text/plain;charset=utf-8,' + encodeURIComponent(data);
  }

  do_download(data, fname) {
    let url = this.get_download_url(data);
    let pom = document.createElement('a');
    pom.setAttribute('href', url);
    pom.setAttribute('download', fname);
    var pp = document.body;
    pp.appendChild(pom);   // needed for firefox
    pom.click();
    pp.removeChild(pom);
  }

  _process_uploads(files, handler, errhandler, binary) {
    files.forEach((file) => {
      let p = binary ? file.arrayBuffer() : file.text();
      p.then((data) => {
        handler(file.name, data);
      }).catch((err) => { errhandler(err); });
    });
  }

  // loads data from the computer to the app, not to a server
  setup_uploader(handler, errhandler, binary = false) {
    let dropzone = (<Dropzone onDrop={(acceptedFiles) => this._process_uploads(acceptedFiles, handler, errhandler, binary)}>
      {({ getRootProps, getInputProps, isDragActive }) => (
        <Paper>
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            {isDragActive ? (<p>Drop the file here</p>) : (<p>Click here, or drag&drop a file</p>)}
          </div>
        </Paper>
      )}
    </Dropzone>);
    return dropzone;
  }

  get_selection() {
    return window.getSelection().toString();
  }

  copy_to_clipboard(sel) {
    try {
      navigator.clipboard.writeText(sel);
    } catch (e) { }
  }

  log_raw(buffer) {
    buffer = buffer.replace(/\r\n/gm, "\\r\\n\r\n");
    buffer = buffer.replace(/([\x00-\x08\x0E-\x1F\x80-\xFF])/gm, function(match, $1, offset, original) { return $1.charCodeAt(0) + " "; });
    console.log(buffer);
  }


  apply_settings() {
    if (this._App.current) this._App.current.updateNexusRef();
    // this._nexus.ui().layout().setState({nexus: this._nexus});

    let sett = this._nexus.settings();
    document.body.classList.remove('inverted');
    if (sett.reverted) document.body.classList.add('inverted');
  }


  // TODO ... something like this? https://www.npmjs.com/package/react-autosuggest
  // used by the input line and a settings form
  // autoprefix offers variables even without entering the @ char
  setup_autocomplete(widget, pos, autoprefix, use_variables, extras) {
    /*
            var t = this;
            widget.autocomplete({ delay: 50, minLength: 2, autoFocus: true,
                    position: pos,
                    focus: function() { return false; },  // do not insert anything on focus
                    source: function(request, response) {
                        var input = this.element;
                        var pos = input[0].selectionStart;
                        var txt = input[0].value;
                        var start = pos;
                        while ((start > 0) && (txt.charAt(start - 1) != ' ')) start--;
                        var word = txt.substr(start, pos - start + 1);
    
                        if ((!autoprefix) && (word.charAt(0) != '@')) {
                            input.autocomplete('close');
                            return [];
                        }
                        if (word.length < 3) {
                            input.autocomplete('close');
                            return [];
                        }
                        if (word[0] == '@') word = word.substr(1);  // strip the heading @
                        var data = [];
                        if (use_variables) {
                            var avail = t._nexus.variables().defined_variables();
                            for (var i = 0; i < avail.length; ++i)
                                if (avail[i].substr(0, word.length) === word)
                                    data.push('@'+avail[i]);
                        }
                        if (extras) {
                            for (var i = 0; i < extras.length; ++i)
                                if (extras[i].substr(0, word.length) === word)
                                    data.push('@'+extras[i]);
                        }
                        response(data);
                    },
                    select: function(event, ui) {
                        var pos = this.selectionStart;
                        var txt = this.value;
                        var start = pos;
                        while ((start > 0) && (txt.charAt(start - 1) != ' ')) start--;
                        var word = txt.substr(start, pos - start + 1);
    
                        var chosen = ui.item.value;
                        txt = txt.substr(0, start) + chosen + txt.substr(pos);
                        this.value = txt;
                        return false;
                    }
            });
    */
  }

};
