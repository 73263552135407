
import { NexusClient } from './nexus.js'
import "./css/styles.css";
import "./css/inverted.css";


(function() {
  let startup = {};
  startup['version'] = '3.1.1';
  startup['api'] = 'https://ironrealms.com/irex/nexusapi/';
  startup['allowtest'] = false;

  let host = window.location.host;
  let params = window.location.search;
  let urlParams = new URLSearchParams(params);
  if ((host.indexOf('betaclient') >= 0) && urlParams.get('enabletest')) startup['allowtest'] = true; // You have seen nothing.

  window.nexusclient = new NexusClient(startup);
  window.nexusclient.setup();
})();
